/* normal card css*/

.normal-card {
    /* width: 90%; */
    /* height: 200px; */
    display: grid;
    place-items: end center;
    padding: 10px;
    /* margin: auto; */
    justify-content: end;
}

.normal-card h1 {
    /* font-size: 18px;
    color: var(--fg); */
    padding: 5px;
    place-items: end center;
    position: relative;
    top: 30%;
    left: 4%;
}

/* expand card css*/

.expanded-card {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    /* width: 500px;
    height: 300px; */
    z-index: 1;
    /* display: grid; */
    /* place-items: center; */
    /* justify-content: center; */
    background-color: #383838;
    overflow-y: scroll;
}

.expanded-card-h {
    /* font-size: 27px; */
    color: var(--fg);
    /* width: 300px; */
    /* padding: 5px; */
    text-align: center;
    /* margin: auto; */
}

.expanded-card p {
    /* width: 300px; */
    place-self: start center;
}