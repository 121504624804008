@import url(https://fonts.googleapis.com/css2?family=Fahkwang&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* normal card css*/

.normal-card {
    /* width: 90%; */
    /* height: 200px; */
    display: grid;
    place-items: end center;
    padding: 10px;
    /* margin: auto; */
    justify-content: end;
}

.normal-card h1 {
    /* font-size: 18px;
    color: var(--fg); */
    padding: 5px;
    place-items: end center;
    position: relative;
    top: 30%;
    left: 4%;
}

/* expand card css*/

.expanded-card {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    /* width: 500px;
    height: 300px; */
    z-index: 1;
    /* display: grid; */
    /* place-items: center; */
    /* justify-content: center; */
    background-color: #383838;
    overflow-y: scroll;
}

.expanded-card-h {
    /* font-size: 27px; */
    color: var(--fg);
    /* width: 300px; */
    /* padding: 5px; */
    text-align: center;
    /* margin: auto; */
}

.expanded-card p {
    /* width: 300px; */
    place-self: start center;
}
